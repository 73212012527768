








































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator';
import { BusyList, BusyObject } from '@/models/Busy';
import { BrandBookmarkContainer } from '@/api/braendz';

@Component({
    components: {
    },
    model: {
        prop: "model",
        event: "visibilityChanged"
    }
})
export default class SaveBrandBookmarkPopup extends Vue {

    // Properties
    public valid = false;

    @Prop({ required: false, default: false })
    public model!: boolean;

    @Prop({ required: true })
    public brandId!: string;

    public containerName: string | null = "My Watch List";

    // Getter & Setter
    public get visible(): boolean {
        return this.model;
    }
    public set visible(value: boolean) {
        this.fireVisibilityChanged(value);
    }

    public get containerNames(): (string | undefined)[] {
        return (this.$store.state.brandBookmarkContainers as BusyList<BusyObject<BrandBookmarkContainer>>)?.list?.map(i => i.object?.name);
    }

    // Watcher:
    @Watch('model')
    public onModelChanged(value: boolean) {
        if(value) {
            this.reset();
        }
    }

    // Lifecycle Methods:

    // Methods:
    public save(): void {
        if (!((this.$refs.form as Vue & { validate: () => boolean }).validate())) {
            return;
        }
        
        const existingContainer = (this.$store.state.brandBookmarkContainers as BusyList<BusyObject<BrandBookmarkContainer>>)?.list?.find(i => i.object?.name === this.containerName)?.object;
        
        if(existingContainer?.id) {
            this.$store.dispatch("addBrandBookmark", { containerId: existingContainer.id, brandId: this.brandId });
        }
        else {
            this.$store.dispatch("addBrandBookmarkContainer", { name: this.containerName, brandBookmarks: [{ id: this.brandId }] } as BrandBookmarkContainer);
        }
        this.close();
    }

    public reset(): void {
        this.containerName = null;
    }

    public close(): void {
        this.visible = false;
    }

    public fireVisibilityChanged(value: boolean): void {
        this.$emit('visibilityChanged', value);
    }
}
