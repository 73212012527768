import { Brand } from '@/api/braendz';

export const dummyBrand = {
    id: "DUMMY",
    name: "CONXEPTA",
    registrationOfficeCode: null,
    brandType: {
      key: "combined",
      description: "Combined",
      descriptionLanguage: "en",
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    applicationNumber: "4711424711424",
    brandState: {
      key: "Marke eingetragen",
      description: "Trade mark registered",
      descriptionLanguage: "en",
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    registrationNumber: "471142471142",
    description: null,
    kindMark: {
      key: "Individual",
      description: null,
      descriptionLanguage: null,
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    imageColor: "schwarz, rot",
    imageClassificationType: {
      key: "Vienna",
      description: "Vienna Classification",
      descriptionLanguage: "en",
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    originBrandType: {
      key: "combined",
      description: "Combined",
      descriptionLanguage: "en",
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    registrationDate: "2014-04-07T00:00:00",
    oppositionPeriodStartDate: "2014-05-09T00:00:00",
    applicationDate: "2014-01-29T00:00:00",
    publicationDate: "2014-05-09T00:00:00",
    oppositionPeriodEndDate: "2014-08-11T00:00:00",
    terminationDate: null,
    expiryDate: "2024-01-31T00:00:00",
    exhibitionDate: null,
    exhibitionName: null,
    asItIsProtectionIndicator: null,
    tradeDistinctivenessIndicator: null,
    tradeDistinctivenessText: null,
    brandLogoUrl: "de/images/3020140132141.png",
    locationAddress: null,
    leadingNiceClass: {
      key: "35",
      description: "Advertising; Business management, organization and administration; Office functions",
      descriptionLanguage: "en",
      shortDescription: "Advertising, Business Management",
      shortDescriptionLanguage: "en"
    },
    correspondenceAddress: {
      name: "Herrn Oliver Poek",
      firstName: null,
      lastName: null,
      companyName: null,
      addressLine: null,
      mailCode: null,
      postOfficeBox: null,
      room: null,
      floor: null,
      building: null,
      street: "Gustav-Brandes-Weg 1b",
      city: "Bremen",
      country: null,
      countryCode: "DE",
      state: null,
      postalCode: "28355"
    },
    ownerAddress: {
      name: "Poek, Oliver",
      firstName: null,
      lastName: null,
      companyName: null,
      addressLine: null,
      mailCode: null,
      postOfficeBox: null,
      room: null,
      floor: null,
      building: null,
      street: null,
      city: "Bremen",
      country: null,
      countryCode: "DE",
      state: null,
      postalCode: "28355"
    },
    representativeAddress: null,
    inventoryType: null,
    goodsServicesClassificationType: {
      key: "Nice",
      description: "Nice Classification",
      descriptionLanguage: "en",
      shortDescription: null,
      shortDescriptionLanguage: null
    },
    goodsServices: [
      {
        descriptionLanguage: null,
        id: null,
        description: "Software",
        wipoId: null,
        niceClass: {
          key: "9",
          description: "Scientific, research, navigation, surveying, photographic, cinematographic, audiovisual, optical, weighing, measuring, signalling, detecting, testing, inspecting, life-saving and teaching apparatus and instruments; Apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling the distribution or use of electricity; Apparatus and instruments for recording, transmitting, reproducing or processing sound, images or data; Recorded and downloadable media, computer software, blank digital or analogue recording and storage media; Mechanisms for coin-operated apparatus; Cash registers, calculating devices; Computers and computer peripheral devices; Diving suits, divers' masks, ear plugs for divers, nose clips for divers and swimmers, gloves for divers, breathing apparatus for underwater swimming; Fire-extinguishing apparatus",
          descriptionLanguage: "en",
          shortDescription: "Electrical Apparatus and Instruments",
          shortDescriptionLanguage: "en"
        }
      },
      {
        descriptionLanguage: null,
        id: null,
        description: "Unternehmens- und Managementberatung",
        wipoId: null,
        niceClass: {
          key: "35",
          description: "Advertising; Business management, organization and administration; Office functions",
          descriptionLanguage: "en",
          shortDescription: "Advertising, Business Management",
          shortDescriptionLanguage: "en"
        }
      },
      {
        descriptionLanguage: null,
        id: null,
        description: "Cloud-Computing; Consulting und Beratung auf dem Gebiet der Computerhardware und -software; Entwicklung, Programmierung und Implementierung von Software; Fachliche Beratung in Bezug auf die Softwareerstellung; IT-Dienstleistungen; Softwaredesign und -entwicklung; Softwareengineering",
        wipoId: null,
        niceClass: {
          key: "42",
          description: "Scientific and technological services and research and design relating thereto; Industrial analysis, industrial research and industrial design services; Quality control and authentication services; Design and development of computer hardware and software",
          descriptionLanguage: "en",
          shortDescription: "Scientific, Technological Services",
          shortDescriptionLanguage: "en"
        }
      }
    ],
    niceClasses: [
      {
        key: "9",
        description: "Scientific, research, navigation, surveying, photographic, cinematographic, audiovisual, optical, weighing, measuring, signalling, detecting, testing, inspecting, life-saving and teaching apparatus and instruments; Apparatus and instruments for conducting, switching, transforming, accumulating, regulating or controlling the distribution or use of electricity; Apparatus and instruments for recording, transmitting, reproducing or processing sound, images or data; Recorded and downloadable media, computer software, blank digital or analogue recording and storage media; Mechanisms for coin-operated apparatus; Cash registers, calculating devices; Computers and computer peripheral devices; Diving suits, divers' masks, ear plugs for divers, nose clips for divers and swimmers, gloves for divers, breathing apparatus for underwater swimming; Fire-extinguishing apparatus",
        descriptionLanguage: "en",
        shortDescription: "Electrical Apparatus and Instruments",
        shortDescriptionLanguage: "en"
      },
      {
        key: "35",
        description: "Advertising; Business management, organization and administration; Office functions",
        descriptionLanguage: "en",
        shortDescription: "Advertising, Business Management",
        shortDescriptionLanguage: "en"
      },
      {
        key: "42",
        description: "Scientific and technological services and research and design relating thereto; Industrial analysis, industrial research and industrial design services; Quality control and authentication services; Design and development of computer hardware and software",
        descriptionLanguage: "en",
        shortDescription: "Scientific, Technological Services",
        shortDescriptionLanguage: "en"
      }
    ],
    viennaClasses: [
      {
        key: "29.1.1",
        description: null,
        descriptionLanguage: null,
        shortDescription: null,
        shortDescriptionLanguage: null
      },
      {
        key: "27.5.9",
        description: null,
        descriptionLanguage: null,
        shortDescription: null,
        shortDescriptionLanguage: null
      },
      {
        key: "29.1.8",
        description: null,
        descriptionLanguage: null,
        shortDescription: null,
        shortDescriptionLanguage: null
      },
      {
        key: "27.5.10",
        description: null,
        descriptionLanguage: null,
        shortDescription: null,
        shortDescriptionLanguage: null
      }
    ],
    internationalRegistrationNumber: null,
    internationalRegistrationDate: null,
    applicationLanguageCode: "DE",
    processes: [
      {
        processType: {
          key: "Eintragung",
          description: "Anmeldeverfahren",
          descriptionLanguage: "",
          shortDescription: null,
          shortDescriptionLanguage: null
        },
        processState: {
          key: "Marke eingetragen",
          description: null,
          descriptionLanguage: null,
          shortDescription: null,
          shortDescriptionLanguage: null
        },
        stateDate: "2014-04-07T00:00:00",
        publicationDate: "2014-05-09T00:00:00",
        publicationIdentifier: "2014-19",
        publicationSection: "1aaa"
      },
      {
        processType: {
          key: "Widerspruch",
          description: "Widerspruchverfahren",
          descriptionLanguage: "",
          shortDescription: null,
          shortDescriptionLanguage: null
        },
        processState: {
          key: "Marke ohne Widerspruch eingetragen",
          description: null,
          descriptionLanguage: null,
          shortDescription: null,
          shortDescriptionLanguage: null
        },
        stateDate: "2014-09-09T00:00:00",
        publicationDate: "2014-10-10T00:00:00",
        publicationIdentifier: "2014-41",
        publicationSection: "2a"
      }
    ],
    deleted: null
} as Brand;