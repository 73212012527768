

















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';
import { AccountInfo } from "@azure/msal-browser";

@Component({})
export default class HiddenBrandOverlay extends Vue {
    // Properties:
    @Prop({ required: true })
    public enabled!: boolean;
    
    // Component Properties:
    
    // Getters:
    public get userAccount(): AccountInfo | null {
        return this.$store.state.userAccount;
    }

    // Component Lifecycle Methods:

    // Methods:
}
