


























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class ShowAllExpander extends Vue {

    // Member
    public expanded = false;
    public expanderVisible = false;

    // Properties
    @Prop({required: true})
    public heightLimit!: number;

    // Getter
    public get contentHeight(): number {
        return (this.$refs.content as HTMLElement)?.scrollHeight ?? 0;
    }

    // Methods
    public toggleExpansion(): void {
        this.expanded = !this.expanded;
    }

    public onContentResize(): void {
        const contentHeight = this.contentHeight;
        this.expanderVisible = !!contentHeight && contentHeight > this.heightLimit;
    }
}
